@import "~antd/dist/antd.css";

/* CUVids green: #5bd1d7, darker green: #336d70 */

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.module-titles {
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding-top: 0.5%;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

[data-theme="dark"] .site-layout-content {
  background: #141414;
}

.rbc-time-content {
  border-top: none;
  padding-top: 0px;
}
.rbc-time-header.rbc-header-overflowing > .rbc-row:nth-of-type(2) {
  display: none;
}
